import React, { FC } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';

import { BREAKPOINTS } from 'common/Image/constants';

import { ThreeImagesProps } from './models';

import './ThreeImages.scss';

const { XS, LG } = BREAKPOINTS;

const ThreeImages: FC<ThreeImagesProps> = ({ desktop, tablet, mobile, className }) => {
  const images = withArtDirection(getImage(mobile.imageData) as IGatsbyImageData, [
    {
      media: `(min-width: ${LG}px)`,
      image: getImage(desktop.imageData ? desktop.imageData : mobile.imageData) as IGatsbyImageData,
    },
    {
      media: `(min-width: ${XS}px)`,
      image: getImage(tablet.imageData ? tablet.imageData : desktop.imageData) as IGatsbyImageData,
    },
  ]);

  return (
    <div className={`three-images ${className}`}>
      <GatsbyImage image={images} alt={desktop?.alt === '[DECORATIVE]' ? '' : desktop?.alt} />
    </div>
  );
};
export default ThreeImages;
