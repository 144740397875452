import React, { FC } from 'react';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { FADEIN } from 'framers/FadeIn';

import Image from 'common/Image';
import Typography, { TypographyProps } from 'common/Typography';
import Button from 'components/Button';
import ThreeImages from 'components/ThreeImages';

import { useScreenService } from 'hooks/useScreenService';

import ChallengeShape from './ChallengeShape';

import {
  BannerImageMappedPositionType,
  BannerProps,
  TypographyMappedVariantType,
} from './models.d';

import './Banner.scss';

const { DOWN } = FADEIN;

const Banner: FC<BannerProps> = ({
  variant,
  title,
  description,
  button,
  image,
  extraAssets,
  banerColor,
  className,
  isH2,
  isContain,
  colorVariantTwoColumnsBanner,
  imageBannerDesktop,
  imageBannerTablet,
  imageBannerMobile,
  motionBanner,
  twoColumnAnimation,
}) => {
  const { isLgUp, isSm } = useScreenService();

  const motionBannerValue = motionBanner?.length ? motionBanner[0] : null;

  const bannerClasses = classNames('banner', className, {
    [`banner--${variant}`]: variant,
    [`banner--${banerColor}`]: banerColor,
    [`banner--without-padding`]: colorVariantTwoColumnsBanner,
    [`banner--videoSrc`]: twoColumnAnimation?.length,
    [`banner--full-video`]: motionBannerValue?.desktopLink[0].url,
  });

  const isContent: boolean = !!title || !!description || !!button;
  const isChallenge: boolean = variant === 'challenge';

  const defaultTitleTypography: TypographyProps = {
    as: 'h2',
    size: { base: 36, md: 46 },
    align: 'center',
    weight: 'semi-bold',
    lineHeight: 'small',
    color: 'white',
  };

  const titleTypographyProps: Partial<TypographyMappedVariantType> = {
    'hero-high': {
      ...defaultTitleTypography,
    },
    'hero-low': {
      ...defaultTitleTypography,
      weight: 'regular',
    },
    'two-sided': {
      ...defaultTitleTypography,
      size: 36,
      align: 'left',
    },
    promo: {
      ...defaultTitleTypography,
      size: 36,
      align: 'left',
    },
    product: {
      ...defaultTitleTypography,
      align: { base: 'center', md: 'left' },
    },
  };

  const defaultDescriptionTypography: TypographyProps = {
    size: { base: 16, md: 22 },
    align: 'left',
    weight: 'regular',
    lineHeight: 'normal',
    color: 'white',
  };

  const descriptionTypographyProps: Partial<TypographyMappedVariantType> = {
    'two-sided': {
      ...defaultDescriptionTypography,
    },
    promo: {
      ...defaultDescriptionTypography,
    },
  };

  const imagePosition: Partial<BannerImageMappedPositionType> = {
    'hero-high': 'center top',
    'hero-low': 'center top',
    'two-sided': 'center top',
    promo: 'center top',
  };

  const { ref, inView } = useInView({ triggerOnce: true, rootMargin: '0px 0px' });

  const staticImages = imageBannerDesktop ? (
    <ThreeImages
      desktop={imageBannerDesktop}
      tablet={imageBannerTablet}
      mobile={imageBannerMobile}
      className={classNames('banner__image', {
        'banner__image--set-height': colorVariantTwoColumnsBanner,
      })}
    />
  ) : null;

  const mobileVideo = isSm
    ? motionBannerValue?.mobileLink[0].url
    : motionBannerValue?.tabletLink[0].url;

  return (
    <div className={bannerClasses} data-testid="banner-item" ref={ref}>
      {isChallenge ? (
        <div className="banner__extra-assets">
          <ChallengeShape className="extra-assets__shape extra-assets__shape--right" />
          <ChallengeShape className="extra-assets__shape extra-assets__shape--left" />
        </div>
      ) : null}
      {image && !imageBannerDesktop ? (
        <Image
          {...image.imageStructure}
          objectFit={isChallenge || isContain ? 'contain' : 'cover'}
          objectPosition={imagePosition[variant]}
          className={classNames('banner__image', {
            'banner__image--set-height': colorVariantTwoColumnsBanner,
          })}
        />
      ) : (
        staticImages
      )}
      {motionBannerValue?.desktopLink[0].url ? (
        <div className="banner__full-video">
          <video autoPlay muted key={isLgUp ? motionBannerValue?.desktopLink[0].url : mobileVideo}>
            <source
              src={isLgUp ? motionBannerValue?.desktopLink[0].url : mobileVideo}
              type="video/mp4"
            />
          </video>
        </div>
      ) : null}
      {twoColumnAnimation?.length && inView ? (
        <div className="banner__video">
          <video autoPlay muted>
            <source src={twoColumnAnimation[0]?.url} type="video/mp4" />
          </video>
        </div>
      ) : null}
      {isContent ? (
        <article
          className={classNames('banner__content', {
            [`banner__content--${banerColor}`]: banerColor,
          })}
        >
          {isChallenge && extraAssets?.[0] ? (
            <Image
              {...extraAssets[0].imageStructure}
              objectFit="contain"
              className="extra-assets__logo"
            />
          ) : null}
          {title ? (
            <Typography
              {...titleTypographyProps[variant]}
              className={classNames('banner__title', {
                [`banner__title--blue`]: colorVariantTwoColumnsBanner,
                [`banner__title--small`]: twoColumnAnimation?.length,
              })}
              animationVariant={DOWN}
              as={isH2 ? 'h2' : 'h1'}
            >
              {title}
            </Typography>
          ) : null}
          {description ? (
            <Typography
              {...descriptionTypographyProps[variant]}
              dangerouslySetInnerHTML={description}
              className={classNames('banner__description', {
                [`banner__description--blue`]: colorVariantTwoColumnsBanner,
                [`banner__description--small`]: twoColumnAnimation?.length,
              })}
              animationVariant={DOWN}
            />
          ) : null}
          {button ? (
            <Button
              {...button}
              className={classNames('banner__button', {
                'banner__button--small': twoColumnAnimation?.length,
              })}
            >
              {button?.label}
            </Button>
          ) : null}
        </article>
      ) : null}
    </div>
  );
};

export default Banner;
