import { ImageStructureMock } from 'common/Image';
import { LinkButtonMock } from 'components/Button';

import { BannerClassMappedMockType, BannerMappedMockType } from '../models.d';

const COMPONENT_BASE_CLASS = 'banner';
const TEST_CLASS = 'test-class';
export const EXAMPLE_TITLE = 'EXAMPLE TITLE';
export const EXAMPLE_DESCRIPTION =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.';

export const BannerClassMock: BannerClassMappedMockType = {
  'hero-high': [COMPONENT_BASE_CLASS, `${COMPONENT_BASE_CLASS}--hero-high`, TEST_CLASS],
  'hero-low': [COMPONENT_BASE_CLASS, `${COMPONENT_BASE_CLASS}--hero-low`, TEST_CLASS],
  'two-sided': [COMPONENT_BASE_CLASS, `${COMPONENT_BASE_CLASS}--two-sided`, TEST_CLASS],
  product: [COMPONENT_BASE_CLASS, `${COMPONENT_BASE_CLASS}--product`, TEST_CLASS],
  logo: [COMPONENT_BASE_CLASS, `${COMPONENT_BASE_CLASS}--logo`, TEST_CLASS],
  promo: [COMPONENT_BASE_CLASS, `${COMPONENT_BASE_CLASS}--promo`, TEST_CLASS],
  challenge: [COMPONENT_BASE_CLASS, `${COMPONENT_BASE_CLASS}--challenge`, TEST_CLASS],
};

export const BannerMock: BannerMappedMockType = {
  'hero-high': {
    variant: 'hero-high',
    title: EXAMPLE_TITLE,
    image: ImageStructureMock,
    className: TEST_CLASS,
  },
  'hero-low': {
    variant: 'hero-low',
    title: EXAMPLE_TITLE,
    image: ImageStructureMock,
    className: TEST_CLASS,
  },
  'two-sided': {
    variant: 'two-sided',
    title: EXAMPLE_TITLE,
    description: EXAMPLE_DESCRIPTION,
    button: LinkButtonMock,
    image: ImageStructureMock,
    className: TEST_CLASS,
  },
  product: {
    variant: 'product',
    title: EXAMPLE_TITLE,
    className: TEST_CLASS,
  },
  logo: {
    variant: 'logo',
    image: ImageStructureMock,
    className: TEST_CLASS,
  },
  promo: {
    variant: 'promo',
    title: EXAMPLE_TITLE,
    description: EXAMPLE_DESCRIPTION,
    button: LinkButtonMock,
    image: ImageStructureMock,
    className: TEST_CLASS,
  },
  challenge: {
    variant: 'challenge',
    button: LinkButtonMock,
    image: ImageStructureMock,
    extraAssets: [
      { imageStructure: { ...ImageStructureMock.imageStructure, alt: 'Extra assets alt text' } },
    ],
    className: TEST_CLASS,
  },
};
